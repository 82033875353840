import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";

import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import {getUser} from '../modules/auth/core/AuthHelpers';
import { useAuth } from "../modules/auth";
const PrivateRoutes = () => {
  const { currentUser } = useAuth();
  const ContentSettingsPage = lazy(
    () => import("../modules/videocontent")
  );
  const ManaeUsersPage = lazy(
    () => import("../modules/content-settings/index")
  );
  const LayoutPage = lazy(() => import("../modules/layout/index"));
  const AudioLayoutPage = lazy(() => import("../modules/audio-layout/index"));
  const CustomersPage = lazy(() => import("../modules/customers/index"));
  const NotificationPage = lazy(() => import("../modules/notification/index"));
  const AdminRolePage = lazy(() => import("../modules/adminroles/index"));
  const DashboardPage = lazy(() => import("../modules/dashboard/Page"));
  const MonetizationPage = lazy(() => import("../modules/monetization/index"));
  const AnalyticsPage = lazy(() => import("../modules/analytics/index"));
  const FeedbackPage =lazy(() => import("../modules/feedbacks/index"))
  const CustomerListPage =lazy(() => import("../modules/customerslist/index"))
  const IntegrationPage = lazy(() => import("../modules/settings/AccountPage"));
  const SettingsPage = lazy(() => import("../modules/settings/index"));
  const FirebasePage = lazy(() => import("../modules/settings/AccountPage"));
  const SettingContentPage = lazy(() => import("../modules/content-settings/index"));
  const AudioPage = lazy(() => import("../modules/audiocontent/index"));
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*"  element={
            <Navigate to={currentUser?.userType === 'SUPERADMIN' ? '/customers/list' : '/dashboard'} />
          }/>
        <Route
          path="/dashboard"
          element={
            <SuspensedView>
              <DashboardPage/>
            </SuspensedView>
          }
        />
        <Route
          path="/content/*"
          element={
            <SuspensedView>
              <ContentSettingsPage />
            </SuspensedView>
          }
        />
         <Route
          path="/manage/*"
          element={
            <SuspensedView>
              <ManaeUsersPage />
            </SuspensedView>
          }
        />
        <Route
          path="monetization/*"
          element={
            <SuspensedView>
              <MonetizationPage/>
            </SuspensedView>
          }
        />
        <Route
          path="analytics/*"
          element={
            <SuspensedView>
              <AnalyticsPage/>
            </SuspensedView>
          }
        />
        <Route
          path="/content/layout/*"
          element={
            <SuspensedView>
              <LayoutPage />
            </SuspensedView>
          }
        />
         <Route
          path="/audio/layout/*"
          element={
            <SuspensedView>
              <AudioLayoutPage />
            </SuspensedView>
          }
        />
        
         <Route
          path="/manage/users/*"
          element={
            <SuspensedView>
              <CustomersPage/>
            </SuspensedView>
          }
        />
          <Route
          path="/manage/notification/*"
          element={
            <SuspensedView>
              <NotificationPage/>
            </SuspensedView>
          }
        />
          <Route
          path="/manage/feedback/*"
          element={
            <SuspensedView>
              <FeedbackPage/>
            </SuspensedView>
          }
        />
         <Route
          path="/manage/setting-contents/*"
          element={
            <SuspensedView>
              <SettingContentPage/>
            </SuspensedView>
          }
        />
         <Route
          path="/manage/usermanagement/*"
          element={
            <SuspensedView>
              <AdminRolePage/>
            </SuspensedView>
          }
        />
        <Route
        path="/audio/*"
        element={
          <SuspensedView>
            <AudioPage/>
          </SuspensedView>
        }
      />
         <Route
          path="/settings/*"
          element={
            <SuspensedView>
              <SettingsPage/>
            </SuspensedView>
          }
        />
         {/* <Route
          path="/integrate/firebase"
          element={
            <SuspensedView>
              <FirebasePage/>
            </SuspensedView>
          }
        /> */}
         {/* <Route
          path="/integrate/smtp"
          element={
            <SuspensedView>
              <FirebasePage/>
            </SuspensedView>
          }
        /> */}
         <Route
          path="customers/*"
          element={
            <SuspensedView>
              <CustomerListPage/>
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
